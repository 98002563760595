<template>
  <!-- eslint-disable -->
  <div class="flex-lg-row-fluid ms-lg-10">
    <div class="card mb-5 mb-xl-10">
      <div class="card-header cursor-pointer">
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Organizacja</h3>
        </div>
        <a v-if="edit.org == false" @click="startEditionOrg" class="btn btn-primary align-self-center">Edytuj</a>
      </div>
      <!--begin::Card header-->
      <!--begin::Card body-->
      <div v-if="edit.org == false" class="card-body p-9">
        <!--begin::Input group-->
        <div class="row mb-7">
          <label class="col-lg-4 fw-bold text-muted">Nazwa</label>
          <div class="col-lg-8 fv-row">
            <span class="fw-bolder fs-6 text-dark">{{ organisation.name }}</span>
          </div>
        </div>
        <div class="row mb-7">
          <label class="col-lg-4 fw-bold text-muted">Dane adresowe</label>
          <div class="col-lg-8 fv-row">
            <span class="fs-6">{{ organisation.street }} {{ organisation.house_number }} <span v-if="organisation.flat_number">/{{ organisation.flat_number }}</span></span>
          </div>
        </div>
        <div class="row mb-7">
          <label class="col-lg-4 fw-bold text-muted"></label>
          <div class="col-lg-8 fv-row">
            <span class="fs-6">{{ organisation.postcode }} {{ organisation.city }}</span>
          </div>
        </div>
        <div class="row mb-7" v-if="organisation.country!='Polska'">
          <label class="col-lg-4 fw-bold text-muted"></label>
          <div class="col-lg-8 fv-row">
            <span class="fs-6">{{ organisation.country }}</span>
          </div>
        </div>
        <div v-if="!adminMode" class="row mb-7">
          <label class="col-lg-4 fw-bold text-muted">Rodzaj klienta</label>
          <div class="col-lg-8 fv-row">
            <span v-if="organisation.internal_client" class="fs-6">Klient wewnętrzny (organ AAB)</span>
            <span v-else class="fs-6">Klient zewnętrzny</span>
          </div>
        </div>
      </div>
      <div v-else class="collapse show">
        <div class="card-body border-top p-9">
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label required fw-bold fs-6">Nazwa</label>
            <div v-if="adminMode" class="col-lg-8 fv-row">
              <input type="text" :class="{ 'is-invalid': errors.name }" @change="errors.name = false" class="form-control form-control-lg form-control-solid" v-model="organisation.name" />
              <div class="fv-plugins-message-container invalid-feedback">{{ errors.name }}</div>
            </div>
            <div v-else class="col-lg-8 fv-row mt-3">
              <span class="fw-bolder fs-6 text-dark">{{ organisation.name }}</span>
            </div>
          </div>
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label required fw-bold fs-6">Adres</label>
            <div class="col-lg-4 fv-row">
              <input type="text" :class="{ 'is-invalid': errors.street }" @change="errors.street = false" class="form-control form-control-lg form-control-solid" v-model="organisation.street" />
              <div class="fv-plugins-message-container invalid-feedback">{{ errors.street }}</div>
            </div>
            <div class="col-lg-2 fv-row">
              <input type="text" :class="{ 'is-invalid': errors.house_number }" @change="errors.house_number = false" class="form-control form-control-lg form-control-solid" v-model="organisation.house_number" />
              <div class="fv-plugins-message-container invalid-feedback">{{ errors.house_number }}</div>
            </div>
            <div class="col-lg-2 fv-row">
              <input type="text" :class="{ 'is-invalid': errors.flat_number }" @change="errors.flat_number = false" class="form-control form-control-lg form-control-solid" v-model="organisation.flat_number" />
              <div class="fv-plugins-message-container invalid-feedback">{{ errors.flat_number }}</div>
            </div>
          </div>
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label required fw-bold fs-6">Kod pocztowy i miasto</label>
            <div class="col-lg-2 fv-row">
              <input type="text" :class="{ 'is-invalid': errors.postcode }" @change="errors.postcode = false" class="form-control form-control-lg form-control-solid" v-model="organisation.postcode" />
              <div class="fv-plugins-message-container invalid-feedback">{{ errors.postcode }}</div>
            </div>
            <div class="col-lg-6 fv-row">
              <input type="text" :class="{ 'is-invalid': errors.city }" @change="errors.city = false" class="form-control form-control-lg form-control-solid" v-model="organisation.city" />
              <div class="fv-plugins-message-container invalid-feedback">{{ errors.city }}</div>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label required fw-bold fs-6">Kraj</label>
            <div v-if="adminMode" class="col-lg-5 fv-row">
              <input type="text" :class="{ 'is-invalid': errors.country }" @change="errors.country = false" class="form-control form-control-lg form-control-solid" v-model="organisation.country" />
              <div class="fv-plugins-message-container invalid-feedback">{{ errors.country }}</div>
            </div>
            <div v-else class="col-lg-8 fv-row mt-3">
              <span class="fs-6">{{ organisation.country }}</span>
            </div>
          </div>

        </div>
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button @click="edit.org = false; reload()" class="btn btn-white btn-active-light-primary me-2">Anuluj</button>
          <button @click="saveOrg" class="btn btn-primary">Zapisz zmiany</button>
        </div>
      </div>
    </div>

    <div v-if="organisation && organisation.role && adminMode" class="card mb-5 mb-xl-10">
      <div class="card-header cursor-pointer">
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Uprawnienia</h3>
        </div>
        <a v-if="adminMode && organisation.id > 1" @click="startEditionPriv" class="btn btn-primary align-self-center">Edytuj</a>
      </div>
      <!--begin::Card header-->
      <!--begin::Card body-->
      <div v-if="edit.priv == false" class="card-body p-9">
        <!--begin::Input group-->
        <div v-if="adminMode" class="row mb-7">
          <label class="col-lg-4 fw-bold text-muted">Rodzaj klienta</label>
          <div class="col-lg-8 fv-row">
            <span v-if="organisation.internal_client" class="fs-6">Klient wewnętrzny (organ AAB)</span>
            <span v-else class="fs-6">Klient zewnętrzny</span>
          </div>
        </div>
        <div v-if="adminMode" class="row mb-7">
          <label class="col-lg-4 fw-bold text-muted">Rola</label>
          <div class="col-lg-8 fv-row">
            <span class="fs-6 fw-bolder text-dark">{{ organisation.role.name }}</span>
          </div>
        </div>
        <div class="row mb-7">
          <label class="col-lg-4 fw-bold text-muted">Uprawnienia</label>
          <div class="col-lg-8 fv-row">
            <span class="fs-6 text-dark">
              <ul class="role">
                <li v-for="scope in organisation.scopes" :key="scope.id">{{ scope.description }}</li>
              </ul>
            </span>
          </div>
        </div>
        <div v-if="clientType == 'internal' && organisation.authority_name" class="row mb-7">
          <label class="col-lg-4 fw-bold text-muted">Organ</label>
          <div class="col-lg-8 fv-row">
            <span class="fs-6 text-dark">{{ organisation.authority_name }}</span>
          </div>
        </div>
      </div>
      <div v-else class="collapse show">
        <div class="card-body border-top p-9">
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">Rodzaj klienta</label>
            <div v-if="organisation.id === 1" class="col-lg-8 fv-row">
              <span class="fs-6 text-dark">Klient wenętrzny (organ AAB)</span>
            </div>
            <div v-else class="col-lg-8 fv-row">
              <select v-model="clientType" aria-label="Wybierz rodzaj klienta" class="form-select form-select-solid form-select-lg">
                <option value="external">Klient zewnętrzny</option>
                <option value="internal">Klient wenętrzny (organ AAB)</option>
              </select>
            </div>
          </div>
          <div v-if="clientType == 'internal'" class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">Rola</label>
            <div v-if="organisation.id === 1" class="col-lg-8 fv-row">
              <span class="fs-6 text-dark">Wszystko: Pełne uprawnienia</span>
            </div>
            <div v-else class="col-lg-8 fv-row">
              <select v-model="roleId" aria-label="Wybierz rolę wewnętrzną" class="form-select form-select-solid form-select-lg">
                <option v-for="role in rolesInternal" v-bind:value="role.id">{{ role.name }}</option>
              </select>
              <div class="fv-plugins-message-container invalid-feedback">{{ errors.roles_internal }}</div>
            </div>
          </div>
          <div v-else class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">Rola</label>
            <div class="col-lg-8 fv-row">
              <select v-model="roleId" aria-label="Wybierz rolę zewnętrzną" class="form-select form-select-solid form-select-lg">
                <option v-for="role in rolesExternal" v-bind:value="role.id">{{ role.name }}</option>
              </select>
              <div class="fv-plugins-message-container invalid-feedback">{{ errors.roles_internal }}</div>
            </div>
          </div>

          <div v-if="roleInfo" class="offset-lg-4 col-lg-8 fv-row">
            <span v-if="roleInfo.scopes.length" class="fs-6 text-dark">
              <ul class="role">
                <li v-for="scope in roleInfo.scopes" :key="scope.id">{{ scope.description }}</li>
              </ul>
            </span>
            <div v-else class="fs-6 text-dark p-8 pt-0">
              Brak uprawnień
            </div>
          </div>

          <div v-if="clientType == 'internal'" class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">Organ RWDZ</label>
            <div v-if="organisation.id === 1" class="col-lg-8 fv-row">
              <span class="fs-6 text-dark">Cała Polska</span>
            </div>
            <div v-else class="col-lg-8 fv-row">
              <select v-model="authority" aria-label="Wybierz organ" class="form-select form-select-solid form-select-lg">
                <option v-for="authority in authorities" v-bind:value="authority.id">{{ authority.nazwa }}</option>
              </select>
              <div class="fv-plugins-message-container invalid-feedback">{{ errors.authority }}</div>
            </div>
          </div>

          <div class="offset-lg-4 alert alert-info" role="alert">
            Uwaga: Zmiany obowiązują dla nowo utworzonych tokenów. Dotychczas utworzone tokeny pracują na poprzednich uprawnieniach do końca ich ważności.
          </div>
        </div>
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button @click="edit.priv = false; roleInfo = false; reload()" class="btn btn-white btn-active-light-primary me-2">Anuluj</button>
          <button @click="savePriv" class="btn btn-primary">Zapisz zmiany</button>
        </div>
      </div>
    </div>

    <div v-if="!profile.is_admin" class="card mb-5 mb-xl-10">
      <!--begin::Card header-->
      <div class="card-header border-0 cursor-pointer" role="button">
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Mój profil</h3>
        </div>
      </div>
      <!--end::Card header-->
      <!--begin::Content-->
      <div class="collapse show">
        <!--begin::Card body-->
        <div class="card-body border-top p-9">
          <div class="d-flex flex-wrap align-items-center">
            <div v-if="edit.names == false">
              <div class="fs-6 fw-bolder mb-1">Imię i nazwisko</div>
              <div class="fw-bold text-gray-600">{{ profile.first_name }} {{ profile.last_name }}</div>
            </div>
            <div v-if="edit.names == false" class="ms-auto">
              <button class="btn btn-primary btn-sm" @click="edit.names = true">Edytuj</button>
            </div>
            <div v-else class="flex-row-fluid">
              <div class="mb-6">
                <div class="row">
                  <label class="col-lg-4 col-form-label required fw-bold fs-6">Imię i nazwisko:</label>
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-6 fv-row">
                        <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Imię" v-model="profile.first_name" />
                      </div>
                      <div class="col-lg-6 fv-row">
                        <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Nazwisko" v-model="profile.last_name" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end pb-6">
                <button @click="edit.names = false; this.$root.updateProfile()" class="btn btn-white btn-active-light-primary me-2">Anuluj</button>
                <button @click="saveNames" class="btn btn-primary">Zapisz zmiany</button>
              </div>
            </div>
          </div>

          <div class="separator separator-dashed my-6"></div>

          <div class="d-flex flex-wrap align-items-center">
            <div v-if="edit.password == false">
              <div class="fs-6 fw-bolder mb-1">Hasło</div>
              <div class="fw-bold text-gray-600">**********</div>
            </div>
            <div v-if="edit.password == false" class="ms-auto">
              <button class="btn btn-primary btn-sm" @click="edit.password = true">Edytuj</button>
            </div>
            <div v-else class="flex-row-fluid">
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-bold fs-6">Obecne hasło:</label>
                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-lg-12 fv-row">
                      <input type="password" :class="{ 'is-invalid': errors.aktualne_haslo }" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Podaj obecne hasło" v-model="password.aktualne_haslo" />
                      <div class="fv-plugins-message-container invalid-feedback">{{ errors.aktualne_haslo }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-bold fs-6">Nowe hasło:</label>
                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-lg-12 fv-row" data-kt-password-meter="true">
                      <input type="password" :class="{ 'is-invalid': errors.haslo }" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Podaj nowe hasło" v-model="password.haslo" />
                      <div class="fv-plugins-message-container invalid-feedback">{{ errors.haslo }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-bold fs-6">Potwierdzenie hasła:</label>
                <div class="col-lg-8">
                  <div class="row invalid">
                    <div class="col-lg-12 fv-row">
                      <input type="password" :class="{ 'is-invalid': errors.haslo_confirmation }" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Powtórz nowe hasło" v-model="password.haslo_confirmation" />
                      <div class="fv-plugins-message-container invalid-feedback">{{ errors.haslo_confirmation }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end pb-6">
                <button @click="edit.password = false; this.$root.updateProfile()" class="btn btn-white btn-active-light-primary me-2">Anuluj</button>
                <button @click="savePassword" class="btn btn-primary">Zapisz zmiany</button>
              </div>
            </div>
          </div>

          <div class="separator separator-dashed my-6"></div>

          <div class="d-flex flex-wrap align-items-center">
            <div>
              <div class="fs-6 fw-bolder mb-1">Adres e-mail</div>
              <div class="fw-bold text-gray-600">{{ profile.email }}</div>
            </div>
            <!--begin::Action-->
            <div class="ms-auto" v-if="1==0">
              <button class="btn btn-primary btn-sm" @click="test">Edytuj</button>
            </div>
            <!--end::Action-->
          </div>

        </div>
        <!--end::Card body-->
      </div>
      <!--end::Content-->
    </div>

  </div>
  <!--end::Content-->
</template>

<script>
export default {
  name: 'ClientDashboard',
  inject: ['$organisation', '$profile'],
  props: ['id'],
  data() {
    return {
      adminMode: false,
      rolesInternal: false,
      rolesExternal: false,
      roleInfo: false,
      roleId: false,
      clientType: false,
      authority: false,
      password: {},
      edit: {
        'org': false,
        'priv': false,
        'names': false,
        'password': false
      },
      authorities: [],
      errors: {}
    }
  },
  mounted() {
    this.$bus.on('updateAdminMode', (value) => { // here you need to use the arrow function
     this.adminMode = value;
    });
    this.adminMode = this.$root.$refs.topbar.adminMode;
    this.reload();
  },
  beforeUnmount() {
    this.$bus.off('updateAdminMode')
  },
  methods: {
    updateRoles: function(type) {
      var self = this;
      this.$root.$refs.api.get('roles?type=' + type).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          if (type == 'internal')
          {
              self.rolesInternal = response.data;
          }
          else if (type == 'external')
          {
              self.rolesExternal = response.data;
          }
          else
          {
              self.rolesInternal = [];
              self.rolesExternal = [];
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    updateRoleInfo: function() {
      //var self = this;
      if (!this.organisation || !this.organisation.role || !this.organisation.role.id)
      {
        return;
      }
      /*
      return;
      var id = false;
      if (this.organisation.internal_client)
      {
          id = this.organisation.role_internal;
      }
      else
      {
          id = this.organisation.role_external;
      }
      this.$root.$refs.api.get('/roles/' + id).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.roleInfo = response.data;
        }
        else
        {
          self.roleInfo = false;
        }
      })
      .catch(function (error) {
        self.roleInfo = false;
        console.log(error);
      });
      */
    },
    update: function() {
      this.$root.updateProfile();
    },
    updateAuthorities() {
      var self = this;
      this.$root.$refs.api.get('authorities').then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.authorities = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    reload: function() {
      this.$root.updateProfile();
      this.updateRoles('internal');
      this.updateRoles('external');
      this.updateRoleInfo();
      this.updateAuthorities();
    },
    saveOrg: function() {
      var self = this;
      var params = {
        'street': self.organisation.street,
        'house_number': self.organisation.house_number,
        'flat_number': self.organisation.flat_number,
        'postcode': self.organisation.postcode,
        'city': self.organisation.city
      };
      if (this.adminMode)
      {
        params['name'] = self.organisation.name;
        params['country'] = self.organisation.country;
      }
      this.$root.$refs.api.patch('/organisations/' + this.organisation.uuid, params).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.organisation = response.data;
          self.edit.org = false;
          self.errors.name = false;
          self.errors.street = false;
          self.errors.house_number = false;
          self.errors.flat_number = false;
          self.errors.postcode = false;
          self.errors.city = false;
          self.errors.country = false;
        }
      })
      .catch(function (error) {
          self.errors = error.response.data;
      });
    },
    saveNames: function() {
      var self = this;
      var params = {
        'first_name': self.profile.first_name,
        'last_name': self.profile.last_name
      };
      this.$root.$refs.api.patch('/me', params).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.$root.updateProfile();
          self.errors = {};
          self.edit.names = false;
        }
      })
      .catch(function (error) {
          self.$root.updateProfile();
          self.errors = error.response.data;
      });
    },
    savePassword: function() {
      var self = this;
      var params = {
        'aktualne_haslo': self.password.aktualne_haslo,
        'haslo': self.password.haslo,
        'haslo_confirmation': self.password.haslo_confirmation
      };
      this.$root.$refs.api.patch('/me', params).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.$root.updateProfile();
          self.errors = {};
          self.password = {};
          self.edit.password = false;
        }
      })
      .catch(function (error) {
          self.$root.updateProfile();
          self.errors = error.response.data;
      });
    },
    savePriv: function() {
      var self = this;
      var params = {};
      if (this.adminMode)
      {
        params = {
          'type': this.clientType,
          'role': this.roleId
        };
        if (this.clientType == 'internal' && this.organisation.id > 1)
        {
          params['authority'] = this.authority;
        }
      }
      var path = '/me/organisation';
      if (this.id)
      {
        path = '/organisations/' + this.id;
      }
      this.$root.$refs.api.patch(path, params).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.$bus.emit("update-organisation");
          self.edit.priv = false;
          self.errors = {}
          //self.roleInfo = false;
          //self.errors = [
        }
      })
      .catch(function (error) {
        if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined')
        {
          self.errors = error.response.data;
        }
        else
        {
          console.log(error);
        }
      });
    },
    startEditionOrg: function() {
      this.errors.name = false;
      this.errors.description = false;
      this.edit.org = true;
      return false;
    },
    startEditionPriv: function() {
      this.edit.priv = true;
    /*
      this.updateRoleInfo();
      this.roleInfo = false;
      return false;
      */
    }
  },
  computed: {
    organisation() {
      return this.$organisation();
    },
    profile() {
      return this.$profile();
    }
  },
  watch: {
    organisation: function(val) {
      this.clientType = ( val['internal_client'] ? 'internal' : 'external' );
      if (val['role']['id'])
      {
        if (this.clientType == 'internal')
        {
          this.roleId = false;
          for(let i = 0; i < this.rolesInternal.length; i++)
          {
            if (this.rolesInternal[i]['id'] == val['role']['id'])
            {
              this.roleId = val['role']['id'];
              break;
            }
          }
        }
        else
        {
          this.roleId = false;
          for(let i = 0; i < this.rolesExternal.length; i++)
          {
            if (this.rolesExternal[i]['id'] == val['role']['id'])
            {
              this.roleId = val['role']['id'];
              break;
            }
          }
        }
      }
      this.authority = val['authority'];
    }
  }
};
</script>

<style>
ul.roles {
  list-style-type: none;
  padding: 0;
}
ul.roles li {
  padding: 0;
  padding-bottom: 8px;
}
</style>
